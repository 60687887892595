import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Seo from "../components/Seo";

import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

import FullWidthImage from "../components/FullWidthImage";
import DisplayContentHeader from "../components/DisplayContentHeader";
import BlogRoll from "../components/BlogRoll";
import Pagination from "../components/Pagination";

// eslint-disable-next-line
export const BlogPageTemplate = ({
  title,
  subtitle,
  footer,
  images,
  author,
  creationDate,
  breadcrumbs,
  options,
  body,
  pageContext,
  posts,
  authors,
  helmet,
}) => {
  const PageContent = HTMLContent || Content;

  let heroImage, footerImage;

  if (images && images.hero) heroImage = getImage(images.hero) || images.hero;
  if (images && images.footer)
    footerImage = getImage(images.footer) || images.footer;

  const showTitle = options.showTitle;

  return (
    <div>
      {helmet || ""}

      {heroImage ? (
        <FullWidthImage
          img={heroImage}
          title={title}
          subheading={subtitle}
          imgPosition="center"
          showTitle={showTitle}
        />
      ) : null}

      <section className="section section--gradient">
        <div className="container">
          <div className="content">
            <div className="content">
              <Link to="/blog" title="Blog">
                <DisplayContentHeader title={title} />
              </Link>
            </div>
            <div className="content">
              <PageContent className="content" content={body} />
            </div>
            <BlogRoll posts={posts} authors={authors} />

            <Pagination pageContext={pageContext} />
          </div>
        </div>
      </section>

      {footerImage ? (
        <FullWidthImage
          img={footerImage}
          subheading={footer}
          imgPosition="center"
          className="footer-image-text"
          showTitle={true}
        />
      ) : null}
    </div>
  );
};

BlogPageTemplate.propTypes = {
  helmet: PropTypes.object,
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
  subtitle: PropTypes.string,
  footer: PropTypes.string,
  images: PropTypes.shape({
    hero: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImageUrl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    footer: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  author: PropTypes.string,
  creationDate: PropTypes.string,
  sortDate: PropTypes.string,
  flags: PropTypes.shape({
    published: PropTypes.bool,
    featured: PropTypes.bool,
  }),
  options: PropTypes.shape({
    showTitle: PropTypes.bool,
    showSummary: PropTypes.bool,
    showTags: PropTypes.bool,
    showComments: PropTypes.bool,
    showAuthorSummary: PropTypes.bool,
    showPrevNext: PropTypes.bool,
    showPopular: PropTypes.bool,
    showRelated: PropTypes.bool,
  }),
  breadcrumbs: PropTypes.shape({
    parent: PropTypes.string,
    parentSlug: PropTypes.string,
    grandparent: PropTypes.string,
    grandparentSlug: PropTypes.string,
  }),
  body: PropTypes.string,
  summary: PropTypes.string,
  pageContext: PropTypes.object,
  posts: PropTypes.array,
  authors: PropTypes.array,
};

const BlogPage = ({ data, pageContext }) => {
  let { markdownRemark: post } = data;
  let { allMarkdownRemark: posts } = data;
  let { authors } = data;

  let imageSrc =
    post.frontmatter.images.hero.childImageSharp.gatsbyImageData.images.fallback
      .src;

  authors = authors.nodes;
  posts = posts.edges;
  if (!post.frontmatter.titles) post.frontmatter.titles = {};
  let seoTitle = post.frontmatter.title;

  if (pageContext.pageNumber) {
    seoTitle = seoTitle + ", Page " + pageContext.humanPageNumber;
  }

  return (
    <Layout>
      <BlogPageTemplate
        title={post.frontmatter.title}
        subtitle={post.frontmatter.titles.subtitle}
        footer={post.frontmatter.titles.footer}
        images={post.frontmatter.images}
        author={post.frontmatter.author}
        creationDate={post.frontmatter.creationDate}
        options={post.frontmatter.options}
        breadcrumbs={post.frontmatter.breadcrumbs}
        body={post.html}
        pageContext={pageContext}
        posts={posts}
        authors={authors}
        helmet={
          <Seo
            title={seoTitle}
            summary={post.frontmatter.summary}
            image={imageSrc}
            article={false}
          />
        }
      />
    </Layout>
  );
};

BlogPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BlogPage;

export const blogPageQuery = graphql`
  query BlogPage($eq: String = "blog-page", $skip: Int!, $limit: Int!) {
    markdownRemark(frontmatter: { advanced: { templateKey: { eq: $eq } } }) {
      html
      frontmatter {
        title
        titles {
          subtitle
          footer
        }
        images {
          hero {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          footer {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
        }
        options {
          showTitle
        }
        summary
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___creationDate] }
      filter: {
        frontmatter: { advanced: { templateKey: { eq: "blog-post" } } }
      }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            advanced {
              templateKey
            }
            category
            tags
            creationDate
            author
            options {
              showTitle
            }
            summary
            flags {
              featured
            }
            images {
              hero {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 900)
                }
              }
            }
          }
        }
      }
    }
    authors: allMarkdownRemark(
      filter: {
        frontmatter: { advanced: { templateKey: { eq: "author-post" } } }
      }
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          name
          social {
            title
            slug
          }
          images {
            smallImage {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
  }
`;
