import * as React from "react";
import PropTypes from "prop-types";
import DisplayCard from "./DisplayCard";
import { v4 } from "uuid";

function returnAuthorSocial(name, authorCollection) {
  let k = null;
  let v = null;
  let social = [];

  authorCollection.forEach((author) => {
    k = author.frontmatter.name;
    v = author.frontmatter.social;
    social[k] = v;
  });

  if (social[name]) {
    return (
      <a target="external" rel="nofollow noopener" href={social[name].slug}>
        {social[name].title}
      </a>
    );
  } else {
    return "";
  }
}

function returnAuthorImage(name, authorCollection) {
  let k = null;
  let v = null;
  let imgs = [];

  authorCollection.forEach((author) => {
    k = author.frontmatter.name;
    v = author.frontmatter.images;
    imgs[k] = v;
  });

  if (imgs[name]) {
    return imgs[name];
  } else {
    return "";
  }
}
function returnAuthorSlug(name, authorCollection) {
  let k = null;
  let v = null;
  let slugs = [];

  authorCollection.forEach((author) => {
    k = author.frontmatter.name;
    v = author.fields.slug;
    slugs[k] = v;
  });

  if (slugs[name]) {
    return slugs[name];
  } else {
    return "";
  }
}

const BlogRoll = ({
  posts,
  authors,

  button = "Read More",
}) => {
  return (
    <div className="columns is-multiline">
      {posts &&
        posts.map(({ node: post }) => {
          if (!post.flags) post.flags = {};
          return (
            <div className="is-parent column is-6" key={v4()}>
              <DisplayCard
                images={post.frontmatter.images}
                relatedPersonImage={returnAuthorImage(
                  post.frontmatter.author,
                  authors
                )}
                title={post.frontmatter.title}
                slug={post.fields.slug}
                category={post.frontmatter.category}
                date={post.frontmatter.creationDate}
                rawDate={post.frontmatter.sortDate}
                excerpt={
                  post.frontmatter.summary
                    ? post.frontmatter.summary
                    : post.excerpt
                }
                showExcerpt={true}
                showTags={true}
                featured={post.flags.featured}
                tags={post.frontmatter.tags}
                buttonText={button}
                relatedPersonSlug={returnAuthorSlug(
                  post.frontmatter.author,
                  authors
                )}
                relatedPersonSocial={returnAuthorSocial(
                  post.frontmatter.author,
                  authors
                )}
                relatedPerson={post.frontmatter.author}
              />
            </div>
          );
        })}
    </div>
  );
};

BlogRoll.propTypes = {
  posts: PropTypes.array,
};

export default BlogRoll;
