import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { kebabCase } from "lodash";
import DisplayDate from "./DisplayDate";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { v4 } from "uuid";
FontAwesomeIcon.autoAddCss = false;

export default function DisplayCard(props) {
  let {
    images = null,
    title,
    slug,
    rawDate = "",
    date = "",
    endDate = "",
    excerpt = "",
    showTags = true,
    featured = false,
    tags = [],
    buttonText = "More",
    buttonClass = "button",
    relatedPerson = "",
    relatedPersonSlug = "",
    relatedPersonImage = null,
    relatedPersonSocial = null,
    category = "",
  } = props;
  if (tags && tags.length) tags = tags.sort((a, b) => a.localeCompare(b));

  if (!images) images = {};
  return (
    <React.Fragment>
      <div className={`card ${featured ? "is-featured" : ""}`}>
        {images.hero ? (
          <div className="card-image p-0">
            <Link to={slug}>
              <figure className="image is-16by9 p-0 m-0">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: images.hero,
                    alt: title,
                    className: "featured-image",
                  }}
                />
              </figure>
            </Link>
          </div>
        ) : null}
        <div className="card-content">
          <div className="content pl-4">
            <h2 className="title is-4">
              <Link to={slug}>{title}</Link>
            </h2>
            {date || relatedPerson ? (
              <h3 className="subtitle is-size-6 is-block mt-2 pt-2">
                {renderAuthorDate(rawDate, date, endDate, null, null, category)}
              </h3>
            ) : null}
            <div className="content">
              {excerpt ? <>{excerpt}</> : null}

              {showTags && tags && tags.length ? (
                <>
                  <div className="tags">
                    <nav className="breadcrumb level" aria-label="breadcrumbs">
                      <ul className="ml-0 mt-2">
                        {tags.map((tag) => {
                          return (
                            <React.Fragment key={v4()}>
                              <li>
                                <Link to={`/tag/${kebabCase(tag)}`}>{tag}</Link>
                              </li>
                            </React.Fragment>
                          );
                        })}
                      </ul>
                    </nav>
                  </div>
                </>
              ) : null}

              <div className="field pt-2">
                <Link className={`${buttonClass}`} to={slug}>
                  <FontAwesomeIcon
                    icon={faAnglesRight}
                    style={{ height: "20px", paddingRight: ".5em" }}
                  />{" "}
                  {buttonText}{" "}
                </Link>
              </div>
            </div>
          </div>

          <div className="media pl-0">
            <div className="media-left" style={{ marginLeft: "-1em" }}>
              <Link to={relatedPersonSlug} title={relatedPerson}>
                <figure className="image is-48x48">
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: relatedPersonImage.smallImage,
                      alt: title,
                      style: {
                        width: "48px",
                        borderRadius: "50%",
                      },
                    }}
                  />
                </figure>
              </Link>
            </div>
            <div className="media-content" style={{ marginLeft: "-1.5em" }}>
              {!relatedPersonSocial ? (
                <>
                  {relatedPersonSlug ? (
                    <p className="title is-4 mt-2 pt-0">
                      <Link to={relatedPersonSlug} title={relatedPerson}>
                        {relatedPerson}
                      </Link>
                    </p>
                  ) : (
                    <p className="title is-4">{relatedPerson}</p>
                  )}
                </>
              ) : (
                <>
                  {relatedPersonSlug ? (
                    <p className="title is-4">
                      <Link to={relatedPersonSlug} title={relatedPerson}>
                        {relatedPerson}
                      </Link>
                    </p>
                  ) : (
                    <p className="title is-4">{relatedPerson}</p>
                  )}
                  <p className="subtitle is-6 mb-4 pb-4">
                    {relatedPersonSocial}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

DisplayCard.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  date: PropTypes.string,
  featured: PropTypes.bool,
  showTags: PropTypes.bool,
  tags: PropTypes.array,
  buttonText: PropTypes.string,
  buttonClass: PropTypes.string,
  relatedPerson: PropTypes.string,
  relatedPersonSlug: PropTypes.string,
  relatedPersonImage: PropTypes.object,
};

function renderAuthorDate(
  rawDate,
  date,
  endDate,
  relatedPerson,
  relatedPersonSlug,
  category
) {
  let formattedDate = "";
  if (date) {
    formattedDate = formatDate(date);
  }

  if (endDate) {
    date = date + " - " + endDate;
  }

  if (date && relatedPerson && relatedPersonSlug) {
    return (
      <span>
        {renderCategory(category)}
        <Link
          to={relatedPersonSlug}
          title={relatedPerson}
          className="no-underline"
        >
          {relatedPerson}
        </Link>
        ,{" "}
        <time dateTime={formattedDate || date}>
          <DisplayDate date={date} />
        </time>
      </span>
    );
  } else if (date && relatedPerson) {
    return (
      <span>
        {renderCategory(category)} {relatedPerson},
        <time dateTime={formattedDate || date}>
          <DisplayDate date={date} />
        </time>
      </span>
    );
  } else if (date) {
    return (
      <>
        {renderCategory(category)}
        <time dateTime={formattedDate || date}>
          <DisplayDate date={date} />
        </time>
      </>
    );
  }
}
<br />;

function renderCategory(category) {
  if (category) {
    return (
      <>
        <Link to={`/category/${kebabCase(category)}/`}>{category}</Link> -{" "}
      </>
    );
  }
}
function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}
